<template>
    <div>
        <nav-bar title="套票推广记录" url="/sale"></nav-bar>
        <div class="company-count">
            <div class="title">累计办理人数</div>
            <van-row>
                <van-col span="6" @click="set_state(-1)">
                    <div class="count">
                        <div>总推广数</div>
                        <p>{{chart.handle_all}}单</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(0)">
                    <div class="count">
                        <div>取消订单</div>
                        <p>{{chart.handle_0}}单</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(1)">
                    <div class="count">
                        <div>待付款</div>
                        <p>{{chart.handle_1}}单</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(2)">
                    <div class="count">
                        <div>付款成功</div>
                        <p>{{chart.handle_2}}单</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(3)">
                    <div class="count">
                        <div>部分退款</div>
                        <p>{{chart.handle_3}}单</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(4)">
                    <div class="count">
                        <div>全部退款</div>
                        <p>{{chart.handle_4}}单</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(5)">
                    <div class="count">
                        <div>订单完成</div>
                        <p>{{chart.handle_5}}单</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(8)">
                    <div class="count">
                        <div>打款完成</div>
                        <p>{{chart.handle_8}}单</p>
                    </div>
                </van-col>
            </van-row>
        </div>

        <div class="top">
            <div class="title2">
                <div class="title-text">
                    套票推广记录
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-list v-model="loading" :finished="finish" finished-text="没有更多了" @load="get_list(state)">
                <van-cell-group class="group-user-list">
                    <van-cell v-for="(item,index) in list" :key="index"
                        :title="item.extend_order_name + '（佣金：￥' + item.extend_money + ')'">
                        <template slot="label">
                            <div>
                                <div>订单号：{{item.extend_order_no}} </div>
                                <div>下单时间：{{formatDate(item.extend_create_time)}}</div>
                                <div v-if="item.extend_pay_time">打款时间：{{formatDate(item.extend_pay_time)}}</div>
                                <div v-if="item.extend_finish_time">完成时间：{{formatDate(item.extend_finish_time)}}</div>
                                <div v-if="item.extend_partner_trade_no!=='0'">打款流水号：{{item.extend_partner_trade_no}}
                                </div>
                                <div v-if="item.extend_remark!=='0'">备注：{{item.extend_remark}}</div>
                            </div>
                        </template>
                        <template slot="default">
                            <van-tag plain type="danger" v-if="item.extend_state==0">订单取消</van-tag>
                            <van-tag plain v-if="item.extend_state==1">待支付</van-tag>
                            <van-tag plain type="success" v-if="item.extend_state==2">支付成功</van-tag>
                            <van-tag plain type="danger" v-if="item.extend_state==3">部分退款</van-tag>
                            <van-tag plain type="warning" v-if="item.extend_state==4">全部退款</van-tag>
                            <van-tag plain v-if="item.extend_state==5">订单完成</van-tag>
                            <van-tag plain type="success" v-if="item.extend_state==8">打款完成</van-tag>
                        </template>

                    </van-cell>
                </van-cell-group>
            </van-list>

            <div v-if="list.length == 0">
                <van-empty description="列表为空" />
            </div>
        </div>


    </div>
</template>

<script>
    import {
        sale_rank,
    } from '@/api/sale.js';
    import {
        package_extend
    } from '@/api/package.js';
    export default {
        name: 'sale_ticket',
        data() {
            return {
                loading: false,
                list: [],
                chart: {},
                finish: false,
                state: -1,
            }
        },
        methods: {
            set_state(state) {
                this.state = state;
                this.list = [];
                this.finish = false;
                this.get_list();
            },



            //获取办理列表
            get_list() {
                var data = {
                    state: this.state,
                    start: this.list.length,
                    count: 20,
                };
                this.loading = true;
                package_extend(data).then(res => {
                    this.loading = false;
                    if (res.code == 1) {
                        this.list = this.list.concat(res.data.info);
                        this.chart = res.data.chart;
                        if (res.data.count < 20) {
                            this.finish = true; //加载完成
                        }
                    }
                });
            },


            formatDate(time) {
                if (!time) {
                    return '';
                }
                var date = new Date(time * 1000);
                var YY = date.getFullYear() + "-";
                var MM =
                    (date.getMonth() + 1 < 10 ?
                        "0" + (date.getMonth() + 1) :
                        date.getMonth() + 1) + "-";
                var DD =
                    date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                var hh =
                    (date.getHours() < 10 ?
                        "0" + date.getHours() :
                        date.getHours()) + ":";
                var mm =
                    (date.getMinutes() < 10 ?
                        "0" + date.getMinutes() :
                        date.getMinutes()) + ":";
                var ss =
                    date.getSeconds() < 10 ?
                        "0" + date.getSeconds() :
                        date.getSeconds();
                return YY + MM + DD + " " + hh + mm + ss;
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .company-count {
        text-align: center;
        padding: 15px;
        background: #fff;
        margin-bottom: 1px;
        margin: 15px;
        border-radius: 20px;
    }

    .company-count .title {
        text-align: left !important;
        padding-left: 5px !important;
        font-family: 'FZCS';
        color: var(--main-color);
    }


    .company-count .van-col {
        padding: 5px;

    }

    .company-count .count {
        height: 55px;
        border-radius: 5px;
        background: var(--main-color);
    }


    .company-count .count div {
        color: #fff;
        font-size: 12px;
        padding-top: 5px;
    }


    .company-count .count p {
        color: #fff;
        font-size: 16px;
    }

    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .title2 {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }
</style>